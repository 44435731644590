import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../../providers/product.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-popular-products',
  templateUrl: './popular-products.component.html',
  styleUrls: ['./popular-products.component.sass']
})
export class PopularProductsComponent implements OnInit {

  public products: any = [];
 
  constructor(public snackBar: MatSnackBar, private router: Router, private productService: ProductService) {
     
  }
 
  ngOnInit() {
    this.getProducts();
  }

  /* Routes starts */
  goToProductDetails(product_id) {
    this.router.navigate(['/home/left-sidebar/product', product_id])
  }
  /* Routes ends */

  // Get products
  getProducts() {
    var state = localStorage.getItem('state')
    this.productService.getProducts(state)
    .then((product: any) => {
      this.products = product
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
