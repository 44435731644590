import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectionStrategy } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { AuthService } from '../../../../providers/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-vendor-carousel',
  templateUrl: './vendor-carousel.component.html',
  styleUrls: ['./vendor-carousel.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush 
})
export class VendorCarouselComponent implements OnInit {

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input('product') product: any = [];
  public config: SwiperConfigInterface = {};

  constructor(public router: Router, public snackBar: MatSnackBar, private authService: AuthService, private wishlistService: WishlistService) {

  }

  ngOnInit() {

  }

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 5,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      }
    }
  }

  // View vendor profile
  public viewVendorProfile(vendor) {
    this.router.navigate(['/home/vendor', vendor.user_id])
  }

  // Add to wishlist
  public addToWishlist(vendor) {
    if (this.authService.getToken()) {
      this.wishlistService.addToWishlist(vendor);
    } else {
      this.openSnackBar('Oops! You must first login', 'success')
    }
  }

  // Present snackbar
  openSnackBar(message, status) {
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
