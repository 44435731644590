import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { urls } from './system.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router, private http: HttpService) { 

  }

  // Register new users
  async register(postData) {
  	return new Promise((resolve, reject) => {
      this.http.post(urls.register, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        }, err => {
         reject(err);
      })
    })
  }

  // Login users
  async login(postData) {
  	return new Promise((resolve, reject) => {
      this.http.post(urls.login, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        }, err => {
         reject(err);
      })
    })
  }

  // Logout from server
  async logout(user_id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(urls.logout + '/' + user_id, '1.0.0')
        .then((res: any) => {
        // Clear localStorage
        this.clearStorage();
        resolve(res);
        }, err => {
        reject(err);
      })
    })
  }

  // Clear all storage
  clearStorage() {
    localStorage.clear();
  }

  // Get basic token
  getToken() {
    return !!localStorage.getItem('basic_token') && !!localStorage.getItem('user_id') && !!localStorage.getItem('account_type')
  }

}