import { Component, OnInit, Input, Output } from '@angular/core';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../../providers/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.sass']
})
export class VendorComponent implements OnInit {

  @Input() vendor: any;

  constructor(public snackBar: MatSnackBar, private authService: AuthService, private cartService: CartService, public productsService: ProductService, private wishlistService: WishlistService, private router: Router ) { 

  }

  ngOnInit(): void {
  }

  // Go to vendor details
  goToVendorDetails(user_id) {
    this.router.navigate(['/home/vendor', user_id])
  }

  // Add to wishlist
  public addToWishlist(vendor) {
    if (this.authService.getToken()) {
      this.wishlistService.addToWishlist(vendor);
    } else {
      this.openSnackBar('Oops! You must first login', 'warning')
    }
  }

  // Add to compare
  public addToCompare(vendor) {
    this.productsService.addToCompare(vendor);
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
