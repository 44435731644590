import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ProductDialogComponent } from '../../products/product-dialog/product-dialog.component';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AuthService } from '../../../../providers/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product-carousel-three',
  templateUrl: './product-carousel-three.component.html',
  styleUrls: ['./product-carousel-three.component.sass']
})
export class ProductCarouselThreeComponent implements OnInit {

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input('product') product: any = [];
  public config: SwiperConfigInterface = {};

  constructor(public snackBar: MatSnackBar, private authService: AuthService, private cartService: CartService, private productsService: ProductService, private wishlistService: WishlistService, private dialog: MatDialog, private router: Router) { 

  }

  ngOnInit() {

  }
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },


      }
    }
  }

  /* Routes starts */
  // Go to product details
  goToProductDetails(product_id) {
    this.router.navigate(['/home/product', product_id])
  }
  /* Routes ends */

  // Add to cart
  public addToCart(product,  quantity: number = 1) {
    this.cartService.addToCart(product,quantity);
  }

  // Add to wishlist
  public addToWishlist(product) {
    if (this.authService.getToken()) {
      this.wishlistService.addToWishlist(product);
    } else {
      this.openSnackBar('Oops! You must first login', 'success')
    }
  }

  // Add to compare
  public addToCompare(product) {
    this.productsService.addToCompare(product);
  }

  // Open product dialog
  public openProductDialog(product){
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
