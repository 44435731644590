import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Functions {
  
    constructor() {

    }

    // Return complete address
    returnLoopedAddress(address, callback) {
        var newAddress = {};
        for (var i = 0; i <= address.length - 1; i++) {
            if (address[i].types[0] == 'route') {
              newAddress['route'] = address[i].long_name;
            }
            if (address[i].types[0] == 'administrative_area_level_2') {
              newAddress['administrative_area_level_2'] = address[i].long_name;
            }
            if (address[i].types[0] == 'administrative_area_level_1') {
              newAddress['administrative_area_level_1'] = address[i].long_name;
            }
            if (address[i].types[0] == 'country') {
              newAddress['country'] = address[i].long_name;
            }
        }
        return callback(newAddress)
    }
    
}