import { Component, OnInit } from '@angular/core';
import { CartService } from '../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../../shared/services/product.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../providers/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from '../../../providers/profile.service';
import { SystemService } from '../../../providers/system.service';
import {
  Flutterwave,
  InlinePaymentOptions,
  PaymentSuccessResponse,
} from "flutterwave-angular-v3";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass']
})
export class CheckoutComponent implements OnInit {

  public cartItems: Observable<CartItem[]> = of([]);
  public buyProducts: CartItem[] = [];

  user: any = {};
  payment_methods: any = [];
  amount: number;
  payments: string[] = ['Create an Account?', 'Flat Rate'];
  paymantWay: string[] = ['Direct Bank Transfer', 'PayPal'];

  publicKey = "FLWPUBK-5dfdce032e9b2600678936d11e6f36cd-X";

  customerDetails: any = {};

  customizations = {
    title: "Gassify - Trovilo Digital Services",
    description: "Gassify Marketplace - Trovilo Digital Services",
    logo: "https://firebasestorage.googleapis.com/v0/b/jeffrey-aboh.appspot.com/o/Gassify%2Fflame_small.png?alt=media&token=9af9284e-cb9c-4306-8faa-5b6f8b1d43a9",
  };

  meta: any = {};

  paymentData: InlinePaymentOptions = {
    public_key: this.publicKey,
    tx_ref: this.generateReference(),
    amount: 10000,
    currency: "NGN",
    payment_options: "card, ussd, mobilemoney, bank_transfer, mono, mpesa",
    redirect_url: "",
    customizations: this.customizations,
    callback: this.makePaymentCallback,
    onclose: this.closedPaymentModal,
    callbackContext: this,
  };

  constructor(private flutterwave: Flutterwave, private systemService: SystemService, private profileService: ProfileService, public snackBar: MatSnackBar, private authService: AuthService, public router: Router, private cartService: CartService, public productService: ProductService) { }

  ngOnInit() {
    this.autoLogin();
  }

  // Check if user is logged in
  autoLogin() {
    if (this.authService.getToken()) {
      this.cartItems = this.cartService.getItems();
      this.cartItems.subscribe(products => this.buyProducts = products);
      this.getTotal().subscribe(amount => this.amount = amount);
      this.getEthionProfile();
    } else {
      this.openSnackBar('Oops! You must first login', 'warning')
      return this.router.navigate(['/pages/my-account'])
    }
  }

  setCustomerData(user) {
    this.meta = { 
      user_id: user.user_id, 
      customer_email: user.email
    };
    this.customerDetails = {
      name: user.first_name + ' ' + user.last_name,
      email: user.email,
      phone_number: user.phone,
    };
  }

  // Get delivery location
  getEthionProfile() {
    var user_id = localStorage.getItem('user_id')
    this.profileService.getEthionProfile(user_id)
    .then((res: any) => {
      this.user = res;
      this.setCustomerData(res)
      this.getPaymentMethods();
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  getPaymentMethods() {
    var currency = localStorage.getItem('country_currency_short');
    this.systemService.getAvailablePaymentMethod(currency)
    .then((res: any) => {
      this.payment_methods = res;
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  // Get total amount in cart
  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

  getCardValidationGateway() {
    this.systemService.getDefaultCardValidationGateway('NG')
    .then((res: any) => {
      this.loadPaymentGateway(res)
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  loadPaymentGateway(data) {
    var endpointUrl = data.payment_method_url;
    var user_id = localStorage.getItem('user_id')
    const postData = {
      user_id: user_id,
      full_name: this.user.first_name + ' ' + this.user.last_name,
      amount_paid: this.amount,
      transaction_name: 'Gassify Payment',
      action_type: 'Card Validation',
      title: "Gassify",
      phone: this.user.country_code + this.user.phone,
      email: this.user.email,
      account_type: 'customer',
      country_currency_short: 'NGN',
      amount: this.amount,
      use_receiving_account: true,
    }
    this.systemService.postPaymentsAPI(endpointUrl, postData)
    .then(async (res: any) => {
      location.href =res.authorization_url;
    })
    .catch((err: any) => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  openPaystack() {

  }

  makePayment() {
    if (!this.amount) {
      return this.openSnackBar('Error! Check your input & try again', 'warning')
    }
    this.paymentData.amount = this.amount;
    this.paymentData.meta = this.meta;
    this.paymentData.customer = this.customerDetails;
    this.flutterwave.inlinePay(this.paymentData);
  }
  makePaymentCallback(response: PaymentSuccessResponse): void {
   // console.log("Payment callback", response);
  }
  closedPaymentModal(): void {
    // console.log("payment is closed");
  }
  generateReference(): string {
    let date = new Date();
    return date.getTime().toString();
  }
}
