import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.sass']
})
export class BrandsComponent implements OnInit {

  brands: string[] = ['all', 'Total'];
  @Output() brandChanged = new EventEmitter();

  constructor() {

  }

  ngOnInit() {

  }

  // Brand is selected
  brandSelect(event) {
    this.brandChanged.emit(
      event.value
    );
  }

}
