import { Component, OnInit } from '@angular/core';
import { SystemService } from '../../../providers/system.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.sass']
})
export class BlogListComponent implements OnInit {

  updates: any = [];

  constructor(private systemService: SystemService) { }

  ngOnInit() {
    this.systemService.getUpdates()
    .then((result: any) => {
      this.updates = result;
     
    }, err => {
      
    })
  }

}
