import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ProductService } from '../../../../providers/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ProductZoomComponent } from '../../products/product-details/product-zoom/product-zoom.component';
import { VendorService } from '../../../../providers/vendor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';

import { ProfileService } from '../../../../providers/profile.service';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.sass']
})
export class VendorDetailsComponent implements OnInit {

  public config: SwiperConfigInterface={};
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;
  public reviews; any = [];
  public vendor: any = {};
  public vendorProfile: any = [];
  public products: any = [];
  ratings: any;
  bigProductImageIndex = 0;

  constructor(public profileService: ProfileService, public snackBar: MatSnackBar, private vendorService: VendorService, private route: ActivatedRoute, public productService: ProductService, public dialog: MatDialog, private router: Router, private cartService: CartService) {
    this.route.params.subscribe(params => {
      const user_id = params['id'];
      this.getVendorData(user_id)
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },
      }
    }
  }

  // Get vendor business profile
  getVendorData(id) {
    this.profileService.getCompleteVendorProfile(id)
    .then((res: any) => {
      this.vendor = res.profile;
      this.vendorProfile = res.user;
      this.getAllVendorReviews(id);
    })
    .catch(err => {
       this.openSnackBar(err.error.message, 'warning')
    })
  }

  // Get vendor reviews
  getAllVendorReviews(id) {
    this.vendorService.getVendorReviews(id)
    .then((res: any) => {
      this.reviews = res.filter(function(vendor) {
        return vendor.product_id == null || !vendor.product_id;
       });
      this.getAllVendorProducts(id)
    })
    .catch(err => {
       this.openSnackBar(err.error.message, 'warning')
    })
  }

  // Get vendor products
  getAllVendorProducts(id) {
    this.vendorService.getVendorProducts(id)
    .then((res: any) => {
      this.products = res;
    })
    .catch(err => {
       this.openSnackBar(err.error.message, 'warning')
    })
  }

  // Open products dialog
  public openProductDialog(vendor, bigProductImageIndex) {
    let dialogRef = this.dialog.open(ProductZoomComponent, {
      data: {vendor, index: bigProductImageIndex },
      panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(vendor => {
      if (vendor) {
        this.router.navigate(['/vendor', vendor.user_id, vendor.business_name]);
      }
    });
  }

  // Toggle ratings
  rate(ratings) {
    this.ratings = Number(ratings)
  }

  // Submit rating
  submitRating(form: NgForm) {
    if (!form.value.full_name || !form.value.message) {
      return this.openSnackBar('All fields are required!', 'warning')
    }
    if (!this.ratings) {
      return this.openSnackBar('Please rate this vendor by selecting your satisfaction level using the emoji faces!', 'warning')
    }
    const postData = {
      client_id: null,
      vendor_id: this.vendor.user_id,
      reviewer_avatar: 'https://firebasestorage.googleapis.com/v0/b/flame-822f3.appspot.com/o/assets%2Fuser.svg?alt=media&token=75a25dd7-92c1-41f1-a148-85751e6c9975',
      reviewer_name: form.value.full_name,
      review_message: form.value.message,
      ratings: this.ratings,      
    }
    this.createVendorReview(postData)
  }

  // Create product review
  createVendorReview(postData) {
    this.vendorService.createReviewVendor(postData)
    .then((res: any) => {
      this.openSnackBar('Hurray! Your review has been submitted successfully.', 'success')
      location.reload();
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
