import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../../providers/product.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.sass']
})
export class ProductLeftSidebarComponent implements OnInit {

  public sidenavOpen: boolean = true;
  public animation: any; 
  public sortByOrder: string = '';
  public page: any;
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public allItems: any = [];
  public products: any = [];

  constructor(public snackBar: MatSnackBar, private productService: ProductService, private route: ActivatedRoute) {
    this.route.params.subscribe(
      (params: Params) => {
        const category = params['category'];
        // Get products
        this.getProducts(category)
      }
    )
  }

  ngOnInit() {

  }

  // Get products by category or state
  getProducts(category) {
    var state = localStorage.getItem('state')
    if (category == 'all') {
      return this.getProductsState(state)
    } else {
      return this.getProductsCategory(category, state)
    }
  }

  // Get products by state
  getProductsState(state) {
    this.productService.getProducts(state)
    .then((products: any) => {
      this.allItems = products;
      this.products = products.slice(0.8);
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
   })
   .catch(err => {
     this.openSnackBar(err.error.message, 'success')
   })
  }

  // Get products by category
  getProductsCategory(category, state) {
    this.productService.getProductsByCategory(category, state)
    .then((products: any) => {
       this.allItems = products;
       this.products = products.slice(0.8);
       this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })
  }

  // Change product view
  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
  
  /* Animations starts */
  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }
  /* Animations ends */

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

  // When page is changes
  public onPageChanged(event){
    this.page = event;
    this.allItems;
    window.scrollTo(0,0);
  }

  // Update price filter
  public updatePriceFilters(price: any) {
    this.allItems = this.products.filter((item) => {
     return item.price >= price.priceFrom && item.price <= price.priceTo
    });
  }

  // Update brands filter
  onBrandsChanged(newBrend) {
    this.allItems = newBrend === 'all' ? this.products : this.products.filter(
      item => item.brand === newBrend
    )
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
