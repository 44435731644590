import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SystemService } from '../../../providers/system.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.sass']
})
export class BlogDetailsComponent implements OnInit {

  update: any;
  updates: any = [];

  constructor(private route: ActivatedRoute, private systemService: SystemService) { 
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.getBlogDetails(id)
    });
  }

  ngOnInit() {
  }

  getBlogDetails(id) {
    this.systemService.getUpdateDetails(id)
    .then((res: any) => {
      this.update = res;
      this.getBlogList()
    }, err => {
     
    })
  }

  getBlogList() {
      this.systemService.getUpdates()
      .then((result: any) => {
        this.updates = result;
       
      }, err => {
       
      })
  }

}
