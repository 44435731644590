import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemoComponent } from './components/demo/demo.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from 'ngx-img-zoom';

import { MainComponent } from './components/main/main.component';
import { HomeComponent } from './components/shop/home/home.component';
import { ShoppingWidgetsComponent } from './components/shared/shopping-widgets/shopping-widgets.component';

import { PagesModule } from './components/pages/pages.module';
import { AppRoutingModule } from './app-routing.module';
import { ShopModule } from './components/shop/shop.module';
import { SharedModule } from './components/shared/shared.module';

import { AgmCoreModule } from '@agm/core';

import { LocationService } from './providers/location.service';
import { AuthService } from './providers/auth.service';
import { Functions } from './providers/functions.service';
import { HttpService } from './providers/http.service';
import { ProductService } from './components/shared/services/product.service';
import { ProfileService } from './providers/profile.service';
import { SystemService } from './providers/system.service';
import { VendorService } from './providers/vendor.service';


import { FlutterwaveModule } from "flutterwave-angular-v3"

@NgModule({
  declarations: [
    AppComponent,
    DemoComponent,
    MainComponent,



  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    SharedModule,
    ShopModule,
    HttpClientModule,
    FlutterwaveModule,
    BrowserAnimationsModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDGFLpz8cQxZuv-8pljHcgGgnpPVvFWVNA',
      libraries: ['places']
    }),
    ReactiveFormsModule,
    AppRoutingModule,
    PagesModule,
    NgxImgZoomModule
  ],
  providers: [
    HomeComponent,
    MainComponent,
    AppComponent,
    ShoppingWidgetsComponent,

    LocationService,
    AuthService,
    Functions,
    HttpService,
    ProductService,
    ProfileService,
    SystemService,
    VendorService,
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
