import { Component, ViewChild, ElementRef } from '@angular/core';
import { MainComponent } from 'src/app/components/main/main.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Gassify: On-Demand LPG Cooking Gas, LPG Products and Accessories';
  @ViewChild('search')
  public searchElementRef: ElementRef;
  refreshed = false;

  constructor(private mainComponent: MainComponent) {
    
  }

  ngOnInit() {
    // Load main component on start
    this.mainComponent.refresh()
    // Hide side scrolling
    window.scroll(0,0);
  }

  ngAfterViewInit() {
    
  }

}
