import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-column',
  templateUrl: './blog-column.component.html',
  styleUrls: ['./blog-column.component.sass']
})
export class BlogColumnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
