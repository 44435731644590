import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.sass']
})
export class PriceComponent implements OnInit {

  public priceFrom: number = 100;
  public priceTo: number = 100000;
  @Output() priceFilters = new EventEmitter();
  public min: number = 1;
  public max: number = 10000000;
  public range = [1, 10000000];
  country_currency_symbol: any;

  constructor() { 

  }

  ngOnInit() {  
    this.country_currency_symbol = localStorage.getItem('country_currency_short');
  }

  // Price change filter range
  priceChanged(event:any) {
    setInterval(() => {
      this.priceFilters.emit(event);
    });
  }

  // Filter price
  priceFilter() {
    this.priceFilters.emit({
      priceFrom: this.priceFrom,
      priceTo: this.priceTo
    });
  }

}
