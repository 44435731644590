import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../providers/profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../providers/auth.service';
import { Router } from '@angular/router';
import { SystemService } from 'src/app/providers/system.service';
import {
  Flutterwave,
  InlinePaymentOptions,
  PaymentSuccessResponse,
} from "flutterwave-angular-v3";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  user: any = {};
  show_mobile_top_up: boolean = false;

  publicKey = "FLWPUBK-5dfdce032e9b2600678936d11e6f36cd-X";

  customerDetails: any = {};

  customizations = {
    title: "Gassify - Trovilo Digital Services",
    description: "Gassify Marketplace - Trovilo Digital Services",
    logo: "https://firebasestorage.googleapis.com/v0/b/jeffrey-aboh.appspot.com/o/Gassify%2Fflame_small.png?alt=media&token=9af9284e-cb9c-4306-8faa-5b6f8b1d43a9",
  };

  meta: any = {};

  paymentData: InlinePaymentOptions = {
    public_key: this.publicKey,
    tx_ref: this.generateReference(),
    amount: 10000,
    currency: "NGN",
    payment_options: "card, ussd, mobilemoney, bank_transfer, mono, mpesa",
    redirect_url: "",
    customizations: this.customizations,
    callback: this.makePaymentCallback,
    onclose: this.closedPaymentModal,
    callbackContext: this,
  };

  constructor(private flutterwave: Flutterwave, private systemService: SystemService, public router: Router, public authService: AuthService, public snackBar: MatSnackBar, private profileService: ProfileService) { }

  ngOnInit(): void {
    this.getEthionProfileData()
  }

  setCustomerData(user) {
    this.meta = { 
      user_id: user.user_id, 
      customer_email: user.email
    };
    this.customerDetails = {
      name: user.first_name + ' ' + user.last_name,
      email: user.email,
      phone_number: user.phone,
    };
  }

  // Get Ethion profile data
  getEthionProfileData() {
    var user_id = localStorage.getItem('user_id')
    this.profileService.getEthionProfile(user_id)
    .then((res: any) => {
      this.user = res;
      this.setCustomerData(res)
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }
  
  // logout user
  logout() {
    var user_id = localStorage.getItem('user_id')
    this.authService.logout(user_id)
    .then((res: any) => {
      this.router.navigate(['/pages/my-account'])
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  goHome() {
    this.router.navigate(['/home/one/all'])
  }

  goToProducts() {
    this.router.navigate(['/home/products/all'])
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

  goToTopUp() {
    this.show_mobile_top_up = true;
  }

  buy(form) {
    if (!form.value.phone || form.value.phone == "" ||
      !form.value.amount || form.value.amount == ""
    ) {
      return this.openSnackBar('Error! Check your input & try again', 'warning')
    }
    this.systemService.getDefaultCardValidationGateway('NG')
    .then((res: any) => {
      this.loadPaymentGateway(res, form.value.phone, form.value.amount)
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  loadPaymentGateway(data, phone, amount) {
    var endpointUrl = data.payment_method_url;
    var user_id = localStorage.getItem('user_id')
    const postData = {
      user_id: user_id,
      full_name: this.user.first_name + ' ' + this.user.last_name,
      amount_paid: amount,
      transaction_name: 'Gassify Payment',
      action_type: 'Card Validation',
      title: "Gassify",
      phone: this.user.country_code + phone,
      email: this.user.email,
      account_type: 'customer',
      country_currency_short: 'NGN',
      amount: amount,
      use_receiving_account: true,
    }
    this.systemService.postPaymentsAPI(endpointUrl, postData)
    .then(async (res: any) => {
      location.href =res.authorization_url;
    })
    .catch((err: any) => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  buyWithFlutterwave(form) {

  }

  makePayment(form) {
    if (!form.value.phone || form.value.phone == "" ||
      !form.value.amount || form.value.amount == ""
    ) {
      return this.openSnackBar('Error! Check your input & try again', 'warning')
    }
    this.paymentData.amount = form.value.amount;
    this.paymentData.meta = this.meta;
    this.paymentData.customer = this.customerDetails;
    this.flutterwave.inlinePay(this.paymentData);
  }
  makePaymentCallback(response: PaymentSuccessResponse): void {
   // console.log("Payment callback", response);
  }
  closedPaymentModal(): void {
    // console.log("payment is closed");
  }
  generateReference(): string {
    let date = new Date();
    return date.getTime().toString();
  }
}
