import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { urls }  from './system.constants';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

	constructor(private http: HttpService) { 

  }

	// Get vendors according to location
	getVendors(state: string) {
  	return new Promise((resolve, reject) => {
      this.http.get(urls.vendor_profile + "/" + "state" + "/" + state, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
	}

  // Get states with available vendors
  getAvailableVendorByState(state: string) {
  	return new Promise((resolve, reject) => {
      this.http.get(urls.vendor_profile + "/" + "state" + "/" + state, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
  }

  // Get vendor profile
  getVendorCompleteDetails(vendor_id: string) {
  	return new Promise((resolve, reject) => {
      this.http.get(urls.profiles + "/" + "complete" + "/" + vendor_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })    
  }

  // Get vendor reviews
  getVendorReviews(vendor_id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(urls.reviews + "/" + "vendor" + "/" + vendor_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })  
  }

  // Get vendor products 
  getVendorProducts(vendor_id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(urls.products + "/" + "vendor" + "/" + vendor_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })    
  }

  // Create a new vendor review
  async createReviewVendor(postData) {
    return new Promise((resolve, reject) => {
    this.http.post(urls.reviews + "/" + "vendor", '1.0.0', postData)
      .then((res: any) => {
      resolve(res);
      },err => {
       reject(err);
      })
    })
  }

}