import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { urls } from './system.constants';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

	constructor(private http: HttpService) { 

  }

	// Get Ethion profile API
	getEthionProfile(user_id: string) {
  	  return new Promise((resolve, reject) => {
      this.http.get(urls.users + "/" + user_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
  }
  
  // Get Gassify profiles API
	getProfile(user_id: string) {
    return new Promise((resolve, reject) => {
    this.http.get(urls.profiles + "/" + user_id, '1.0.0')
      .then((res: any) => {
      resolve(res);
      },err => {
       reject(err);
      })
    })
  }

	// Get Gassify client profile API
	getFlameClientProfile(user_id: string) {
  	  return new Promise((resolve, reject) => {
      this.http.get(urls.client_profile + "/" + user_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
	}

	// Get Gassify vendor profile API
	getFlameVendorProfile(user_id: string) {
  	  return new Promise((resolve, reject) => {
      this.http.get(urls.vendor_profile + "/" + user_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
	}

	// Get Gassify vendor profile API
	getCompleteVendorProfile(user_id: string) {
    return new Promise((resolve, reject) => {
    this.http.get(urls.profiles + "/" + "complete" + "/" + user_id, '1.0.0')
      .then((res: any) => {
      resolve(res);
      },err => {
       reject(err);
      })
    })
  }

  // Get Gassify profile API
  async getFlameProfile(user_id: string) {
      return new Promise((resolve, reject) => {
      this.http.get(urls.client_profile + "/" + user_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
  }

  // Create Gassify client profile
  async createFlameClientProfile(postData) {
      return new Promise((resolve, reject) => {
      this.http.post(urls.client_profile, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
  }

  // Create Gassify vendor profile
  async createFlameVendorProfile(user_id: string, postData) {
      return new Promise((resolve, reject) => {
      this.http.post(urls.vendor_profile + "/" + user_id, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
  }

  // Change User Password
  async changePassword(user_id: string, postData) {
    return new Promise((resolve, reject) => {
    this.http.post(urls.change_password + "/" + user_id, '1.0.0', postData)
      .then((res: any) => {
      resolve(res);
      },err => {
      reject(err);
      })
    })
  }

  // Update Ethion profile API
  async	updateEthionProfile(user_id: string, postData) {
  	  return new Promise((resolve, reject) => {
      this.http.put(urls.users + "/" + user_id, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
	}

	// Update Gassify client profile API
  async	updateFlameClientProfile(user_id: string, postData) {
  	  return new Promise((resolve, reject) => {
      this.http.put(urls.client_profile + "/" + user_id, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
	}

	// Update Gassify vendor profile API
  async	updateFlameVendorProfile(user_id: string, postData) {
  	  return new Promise((resolve, reject) => {   
      this.http.put(urls.vendor_profile + "/" + user_id, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
	}

}