import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../providers/product.service';
import { VendorService } from '../../../providers/vendor.service';
import { ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomeComponent implements OnInit {
  products = [];
  vendors = [];
  public banners = [];
  public slides = [
    { title: '', subtitle: '', image: 'assets/1920x805/3.png' }
  ];

  constructor(public snackBar: MatSnackBar, private route: ActivatedRoute, private vendorService: VendorService, private productService: ProductService) { 
    this.route.params.subscribe(params => {
      const state = params['state'];
      // Get vendors based on their state
      this.getVendors()
    });
  }

  ngOnInit() {

  }

  // Get vendors
  getVendors() {
    var state = 'Lagos';
    this.vendorService.getVendors(state)
    .then((res: any) => {
      this.vendors = res;
      // Get products
      this.getProducts(state);
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })   
  }

  // Get products
  getProducts(state) {
    this.productService.getProducts(state)
    .then((res: any) => {
      this.products = res;
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
