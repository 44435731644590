import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService } from '../../../providers/location.service';
import { AuthService } from '../../../providers/auth.service';
import { SystemService } from '../../../providers/system.service';
import { Functions } from '../../../providers/functions.service';
import { ProfileService } from '../../../providers/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.sass']
})
export class MyAccountComponent implements OnInit {

  registerStep = 'register1';
  country_currency_symbol = 'NGN';
  vendor = false;
  customer = true;
  accountType = 'customer';
  countries: any = [];
  country: any = [];
  otpCode: any;
  user: any = [];
  cylinder_exchange = false;
  equipment_maintenance = false;
  repairs_replacements = false;
  newsletter = false;
  terms_of_use = false;
  
  constructor(public router: Router, private profileService: ProfileService, private functions: Functions, private systemService: SystemService, private authService: AuthService, private locationService: LocationService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.autoLogin();
  }

  // Check if user is logged in
  autoLogin() {
    if (this.authService.getToken()) {
        return this.router.navigate(['/pages/dashboard'])
    } else {
      this.getAllCountries();
      return this.authService.clearStorage()
    }
  }

  // Login
  login(form: NgForm) {
    var mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (mailformat.test(form.value.email) != true) {
       return this.openSnackBar('Oops! You have entered an invalid email', 'warning')
    }
    if (!form.value.email || !form.value.password) {
      return this.openSnackBar('All fields are required!', 'warning')
    }
    if (+form.value.password.length < 6) {
      return this.openSnackBar('Password length must be more that 6 characters!', 'warning')
    }
    const postData = {
      email: form.value.email,
      password: form.value.password,
    }
    this.authService.login(postData)
    .then((res: any) => {
      if (res.role == 'customer') {
        this.saveToLocalStorage(res);
        this.openSnackBar('Login successful!', 'success')
        this.router.navigate(['/pages/dashboard'])
      } else {
        return this.openSnackBar('Unauthorized! Please change account role', 'warning')
      }
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  /* Registration starts */
  register(step, form) {
    if (step == 'register2') {
      this.registerWithEmailAndPassword(step, form);
    }
    if (step == 'register3') {
      this.startVerification(step, form);
    }
    if (step == 'register4') {
      this.verifyOtp(step, form);
    }
    if (step == 'register5') {
      this.savePersonalProfile(step, form)
    }
    if (step == 'register6') {
      this.saveBusinessProfile(step, form)
    }
    if (step == 'register7') {
      this.saveAcknowledgement(step)
    }
  }

  // Register with email and password
  registerWithEmailAndPassword(step, form: NgForm) {
    var mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (mailformat.test(form.value.email) != true) {
       return this.openSnackBar('Oops! You have entered an invalid email', 'warning')
    }
    if (!form.value.email || !form.value.password || !this.accountType) {
       return this.openSnackBar('All fields are required!', 'warning')
    } 
    if (+form.value.password.length < 6) {
       return this.openSnackBar('Password length must be more that 6 characters!', 'warning')
    }
    if (!this.accountType) {
      return this.openSnackBar('Please select your account type!', 'warning')
    }
    // Post to Ethion Users API
    const postData = {
      email: form.value.email,
      password: form.value.password,
      reg_platform: "FLAME",
      reg_platform_id: "ETHFLM01",
      account_type: this.accountType,
      platform_name: "FLAME",
      platform_id: "ETHFLM01",
    }
    this.authService.register(postData)
    .then((res: any) => {
      // Save token
      localStorage.setItem('basic_token', res.user.basic_token);
      // Store user details
      this.user.user_id = res.profile.user_id;
      this.user.email = form.value.email;
      this.user.account_type = this.accountType;
      // Next step
      this.registerStep = step;
    })
    .catch(err => {
        this.openSnackBar(err.error.message, 'warning');
    })
  }

  // Verification
  startVerification(step, form: NgForm) {
    if (!form.value.phone) {
       return this.openSnackBar('Please enter a valid mobile number!', 'warning')
    }
    if (!this.country) {
      return this.openSnackBar('Please select your country!', 'warning')
    }
    // Get OTP
    const postData = {
      To: '0' + form.value.phone,
      CountryCode: this.country.country_call_code,
    } 
    this.systemService.verifyPhoneNumber(postData)
    .then((res: any) => {
     // Store user details
     this.user.phone = form.value.phone;
     // Store otp code
     this.otpCode = res.message;
     this.openSnackBar('Verification code sent successfully!', 'success');
     // Next step
     this.registerStep = step;
    })
    .catch(err => {
     this.openSnackBar('Unable to verify your mobile number! Try again later', 'warning')
    })
  }

  // Verify one time password 
  verifyOtp(step, form: NgForm) {
    if (form.value.otp != this.otpCode) {
       return this.openSnackBar('Oops! You have entered a wrong code, Try again', 'warning')
    }
    this.user.phone_verified = true;
    this.openSnackBar('Verification successful!', 'success')
    this.registerStep = step;
  }

  // Save personal profile
  savePersonalProfile(step, form: NgForm) {
    if (!form.value.first_name || !form.value.last_name || !this.user.administrative_area_level_2 || !this.user.administrative_area_level_1) {
      return this.openSnackBar('Please complete the required fields!', 'warning')
    }
    // Post to Ethion Users API
    const postData = {
      first_name: form.value.first_name,
      last_name: form.value.last_name,
      phone: this.user.phone,
      phone_verified: this.user.phone_verified,
      last_verified_phone: this.user.phone,
      address: this.user.route,
      city: this.user.administrative_area_level_2,
      state: this.user.administrative_area_level_1,
      zip_code: this.user.postal_code,
      country: this.country.country_name,
      country_code: this.country.country_call_code,
      country_shortcode: this.country.country_iso_code_2,
    }
    this.profileService.updateEthionProfile(this.user.user_id, postData)
    .then((res: any) => {
      // Update Data to Gassify Client Profile API
      this.updateProfile(this.user.user_id, step)
    }) 
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning');
    })
  }

  updateProfile(user_id, step) {
    // Data for Gassify profile API
    const postData = {
      location_lat: 0,
      location_lng: 0,
      country_currency_short: this.country.country_currency_short,
      country_currency_symbol: this.country.country_currency_symbol,
      country_currency_full: this.country.country_currency_full,

    };  
    if (this.accountType == 'customer') {
      this.updateFlameClientProfile(user_id, postData)
    }
    if (this.accountType == 'vendor') {
      this.updateFlameVendorProfile(user_id, postData, step)
    }
  }

  // Update Gassify client profile
  updateFlameClientProfile(user_id, postData) {
    this.profileService.updateFlameClientProfile(user_id, postData)
    .then((res: any) => {
      // Next step
      this.registerStep = 'register6';
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning');
    })
  } 

  // Update Gassify vendor profile
  updateFlameVendorProfile(user_id, postData, step) {
    this.profileService.updateFlameVendorProfile(user_id, postData)
    .then((res: any) => {
      // Next step
      this.registerStep = step;
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning');
    })    
  }

  // Save business profile
  saveBusinessProfile(step, form: NgForm) {
    if (!form.value.business_name || !form.value.delivery_price || !form.value.delivery_message || !this.user.business_city || !this.user.business_state) {
      return this.openSnackBar('Please complete the required fields!', 'warning')
    }
    const postData = {
      business_name: form.value.business_name,
      business_location: this.user.business_location,
      business_city: this.user.business_city,
      business_state: this.user.business_state,
      business_zip_code: this.user.business_postal_code,
      business_location_lat: 0,
      business_location_lng: 0,
      delivery_price: form.value.delivery_price,
      delivery_message: form.value.delivery_message,
      business_reg_no: form.value.business_reg_no,
      cylinder_exchange_service: this.cylinder_exchange,
      repairs_replacement_service: this.repairs_replacements,
      equipment_maintenance_service: this.equipment_maintenance,      
    }
    this.profileService.updateFlameVendorProfile(this.user.user_id, postData)
    .then((res: any) => {
      // Next step
      this.registerStep = step;
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning');
    })
  }

  saveAcknowledgement(step) {
    if (this.terms_of_use == false) {
      return this.openSnackBar('You must agree to our terms of use!', 'warning')
    }
    const postData = {
      newsletter: this.newsletter,
      terms_of_use: this.terms_of_use,
    }
    this.profileService.updateEthionProfile(this.user.user_id, postData)
    .then((res: any) => {
      // Next step 
      this.registerStep = step;
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }
  /* Registration ends */

  // Get all countries
  getAllCountries() {
    this.locationService.getAllCountries((error, countries) => {
      if (error) {
        this.openSnackBar(error.error.message, 'warning')
      }
      if (countries) {
        this.countries = countries;
      }
    })
  }

  // Change country
  public async changeCountry(item){
    this.country = item;
  }

  /* Store profile to localStorage starts */
  saveToLocalStorage(data) {
    localStorage.setItem('user_id', data.user.user_id);
    localStorage.setItem('first_name', data.user.first_name);
    localStorage.setItem('last_name', data.user.last_name);
    localStorage.setItem('email', data.user.email);
    localStorage.setItem('avatar', data.user.avatar);
    localStorage.setItem('address', data.user.address);
    localStorage.setItem('city', data.user.city);
    localStorage.setItem('state', data.user.state);
    localStorage.setItem('basic_token', data.user.basic_token);
    localStorage.setItem("country_name", data.user.country);
    localStorage.setItem('country_iso_code_2', data.user.country_shortcode);
    localStorage.setItem('country_call_code', data.user.country_code);
    localStorage.setItem('account_type', data.profile.account_type);
    localStorage.setItem('business_name', data.profile.business_name);
    localStorage.setItem('business_logo', data.profile.business_logo);
    localStorage.setItem('business_address', data.profile.business_location);
    localStorage.setItem('business_city', data.profile.business_city);
    localStorage.setItem('business_state', data.profile.business_state);
    localStorage.setItem('country_currency_short', data.profile.country_currency_short);
    localStorage.setItem('country_currency_symbol', data.profile.country_currency_symbol);
    localStorage.setItem('country_currency_full', data.profile.country_currency_full);
  }
  /* Store profile to localStorage ends */

  // Account type checkbox is changed
  isAccountTypeChecked($event, type) {
    if (type == 'customer' && $event.checked == true) {
      this.vendor = false;
      this.customer = true;
      this.accountType = 'customer';
    }
    if (type == 'vendor' && $event.checked == true) {
      this.vendor = true;
      this.customer = false;
      this.accountType = 'vendor';
    }
  }

  // Additional service checkbox is changed
  isServiceChecked($event, type) {
    if (type == 'Cylinder Exchange' && $event.checked == true) {
      this.cylinder_exchange = true;
    }
    if (type == 'Equipment Maintenance' && $event.checked == true) {
      this.equipment_maintenance = true;
    }
    if (type == 'Repairs & Replacements' && $event.checked == true) {
      this.repairs_replacements = true;
    }
  }

  // TOU and Mailing list checkbox is changed
  isAcknowledgementChecked($event, type) {
    if (type == 'newsletter' && $event.checked == true) {
      this.newsletter = true;
    }
    if (type == 'terms_of_use' && $event.checked == true) {
      this.terms_of_use = true;
    }
  }

  // Get personal address from google maps
  getPersonalAddress(place) {
   this.functions.returnLoopedAddress(place.address_components, (response) => {
     if (response) {
        this.user.route = response.route;
        this.user.administrative_area_level_1 = response.administrative_area_level_1;
        this.user.administrative_area_level_2 = response.administrative_area_level_2;
     }
   })
  }

  // Get business address from google maps
  getBusinessAddress(place) {
   this.functions.returnLoopedAddress(place.address_components, (response) => {
     if (response) {
        this.user.business_location = response.route;
        this.user.business_state = response.administrative_area_level_1;
        this.user.business_city = response.administrative_area_level_2;
     }
   })
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
