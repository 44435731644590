import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';
import { AuthService } from '../../../../providers/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass']
})
export class ProductComponent implements OnInit {

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input() product: any;

  constructor(public snackBar: MatSnackBar, private authService: AuthService, private cartService: CartService, public productsService: ProductService, private wishlistService: WishlistService, private dialog: MatDialog, private router: Router ) { 

  }

  ngOnInit() {

  }

  // Go to product details
  goToProductDetails(product_id) {
    this.router.navigate(['/home/product', product_id])
  }

  // Add to cart
  public addToCart(product,  quantity: number = 1) {
    this.cartService.addToCart(product,quantity);
  }

  // Add to wishlist
  public addToWishlist(product) {
    if (this.authService.getToken()) {
      this.wishlistService.addToWishlist(product);
    } else {
      this.openSnackBar('Oops! You must first login', 'success')
    }
  }

  // Add to compare
  public addToCompare(product) {
    this.productsService.addToCompare(product);
  }

  // Open product dialog
  public openProductDialog(product){
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }
  
}
