import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MainComponent } from '../../main/main.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})
export class MenuComponent implements OnInit {

  constructor(private mainComponent: MainComponent, public router: Router) { }

  ngOnInit() {

  }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }

  /* Routes starts */
  goHome() {
    this.mainComponent.refresh();
  }
  /* Routes ends */

}
