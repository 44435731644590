import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { urls, ipifyBaseUrl, baseUrl } from './system.constants';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

	constructor(private http: HttpService) { 

  }

	// Get Countries from API
	getCountries() {
  	return new Promise((resolve, reject) => {
      this.http.get(urls.countries, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
	}

  // Get available states
  getAvailableStates(country_name: string) {
  	return new Promise((resolve, reject) => {
      this.http.get(urls.countries + "/" + country_name + "/" + "states", '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
	}

  // Get Blog Updates
  getUpdates() {
    return new Promise((resolve, reject) => {
      this.http.get(urls.updates, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })
  }

  // Get Blog Update Details
  getUpdateDetails(update_id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(urls.updates + "/" + update_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })      
  }

  // Get ip address
  getIpAddress() {
    return new Promise((resolve, reject) => {
      this.http.lookUpIpAddress()
        .then((res: any) => {
        resolve(res);
        },err => {
        reject(err);
        })
    })      
  }

  // Get coordinates
  getCoordinates(ip_address: string) {
    return new Promise((resolve, reject) => {
      this.http.get(urls.location + "/" + ip_address, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
        reject(err);
        })
    })      
  }

  // Get available payment methods
  getAvailablePaymentMethod(currency: string) {
    return new Promise((resolve, reject) => {
      this.http.get(urls.settings + "/" + "payment-methods" + "/" + currency, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
      })
    }) 
  }
  
  // Verify phone number
  async verifyPhoneNumber(postData) {
      return new Promise((resolve, reject) => {
      this.http.post(urls.verification, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
      })
    })
  }

  // Post new support request
  async createSupportRequest(postData) {
    return new Promise((resolve, reject) => {
      this.http.post(urls.support, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
      })
    })
  }

 // Get default card validation gateway
 getDefaultCardValidationGateway(country_shortcode: string) {
  return new Promise((resolve, reject) => {
    this.http.get(urls.cards + "/" + "settings" + "/" + "card-validation" + "/" + country_shortcode, '1.0.0')
      .then((res: any) => {
      resolve(res);
      },err => {
       reject(err);
      })
    })
  }

  // update payment API using POST method
  async postPaymentsAPI(endpointUrl: string, postData) {
    return new Promise((resolve, reject) => {
      this.http.post(baseUrl + endpointUrl, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
      })
    })
  }


}