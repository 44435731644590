import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../services/cart.service';
import { Observable } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../services/product.service';
import { LocationService } from '../../../providers/location.service';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-shopping-widgets',
  templateUrl: './shopping-widgets.component.html',
  styleUrls: ['./shopping-widgets.component.sass'],
})
export class ShoppingWidgetsComponent implements OnInit {

  products: any;
  indexProduct: number;
  public sidenavMenuItems:Array<any>;
  @Input() shoppingCartItems: CartItem[] = [];
  country_currency_symbol: any;

  constructor(private ngZone: NgZone, private locationService: LocationService, private cartService: CartService, public productService: ProductService) { 
    
  }

  ngOnInit() {
    this.getCountryCurrency();
  }

  // Get country currency
  getCountryCurrency() {
    this.locationService.getAllCountries((error, countries) => {
      if (error) {

      }
      if (countries) {
        this.locationService.getCurrentCountry(countries, (error, address, country) => {
          if (error) {

          }
          if (address && country) {
            this.country_currency_symbol = country.country_currency_symbol
          }
        })
      }
    })
  }

  // Change currency
  changeCurrency(country) {
    this.ngZone.run(() => {
      this.country_currency_symbol = country.country_currency_symbol;
    })
  }

  // Update currency
  public updateCurrency(curr) {
    this.productService.currency = curr;
  }

  // Remove item from cart
  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

  // Get total amount
  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

}
