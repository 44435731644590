// Get Current Date
export function todayDate() {
	let today = new Date();
	let dd = today.getDate();
	let mm = today.getMonth()+1; 
	let mmStr = mm.toString();
	let ddStr = dd.toString()
	let yyyy = today.getFullYear();

	if(dd<10) {
		ddStr = '0' + dd;
	} 

	if(mm<10) {
		mmStr = '0' + mm;
	} 
	return yyyy + '-' + mm + '-'+ dd;
}

// Ethion API Service 1 baseUrl
//export const baseUrl = "https://api.ethion.info/";

// Heroku Server URL
export const baseUrl = "https://api.trovilo.co:3100/v2/";

// Test Server URL
//export const baseUrl = "https://ethion-api-dev.herokuapp.com/v2/";

// Localhost Server URL
//export const baseUrl = "http://localhost:3000/v2/";

// Ioify.com base url
export const ipifyBaseUrl = "https://api.ipify.org/?format=json";

// API Routes
export const urls = {
	countries: baseUrl + "platform/flame/countries",
	settings: baseUrl + "platform/flame/settings",
	cards: baseUrl + "platform/trovilo/cards",
	login: baseUrl + "login",
	logout: baseUrl + "logout",
	register: baseUrl + "register",
	forgot_password: baseUrl + "forgot-password",
	change_password: baseUrl + "change-password",
	users: baseUrl + "users",
	profiles: baseUrl + "platform/flame/profiles",
	client_profile: baseUrl + "platform/flame/client-profile",
	vendor_profile: baseUrl + "platform/flame/vendor-profile",
	products: baseUrl + "platform/flame/products",
	reviews: baseUrl + "platform/flame/reviews",
	favorites: baseUrl + "platform/flame/favorites",
	chats: baseUrl + "platform/flame/chat",
	orders: baseUrl + "platform/flame/orders",
	transactions: baseUrl + "platform/flame/transactions",
	upgrade: baseUrl + "platform/flame/upgrade",
	updates: baseUrl + "updates",
	support: baseUrl + "support",
	send_sms: baseUrl + "send-sms",
	tracking: baseUrl + "platform/flame/track",
	verification: baseUrl + "verification",
	analytics: baseUrl + "analytics",
	find_customers: baseUrl + "platform/flame/find-customers",
	media: baseUrl + "media",
	paystack: baseUrl + "payment/paystack",
	wallet: baseUrl + "payment/wallet",
	topup: baseUrl + "platform/flame/top-up",
	location: baseUrl + "location",
}