import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { Functions } from '../../../providers/functions.service';
declare var google;

@Component({
  selector: 'AutocompleteComponent',
  template:`<mat-form-field class="w-100"><input matInput 
        type="text"
        [(ngModel)]="autocompleteInput"
        #addresstext required placeholder="Address (required)"
        ></mat-form-field>`,
})

export class AutocompleteAddressComponent implements OnInit, AfterViewInit {

  @Input() adressType: string;
  @Input() country: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  @ViewChild('citytext') citytext: any;

  autocompleteInput: string;
  queryWait: boolean;
  place: any;
  state: string;
  city: string;
  postal_code: string;

  constructor(private functions: Functions) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
      const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
          {
              componentRestrictions: { country: this.country },
              types: [this.adressType]
          });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          this.invokeEvent(place);
      });
  }

  invokeEvent(place) {
    this.setAddress.emit(place);
  }

}
