import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { SystemService } from '../../../providers/system.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {

  constructor(private systemService: SystemService, public snackBar: MatSnackBar,) { }

  ngOnInit() {
  }

  send(form: NgForm) {
    var user_id = localStorage.getItem('user_id')
    var avatar = localStorage.getItem('avatar')
    if (!form.value.full_name || !form.value.email || !form.value.message) {
      return this.openSnackBar('Please complete the required fields!', 'warning')
    }
    // Create postData
    const postData = {
      support_id: this.generateSupportID(),
      user_id: user_id || null,
      avatar: avatar || 'https://firebasestorage.googleapis.com/v0/b/flame-822f3.appspot.com/o/assets%2Fuser.svg?alt=media&token=75a25dd7-92c1-41f1-a148-85751e6c9975',
      first_name: form.value.full_name,
      last_name: null,
      email: form.value.email,
      phone: null,
      subject: form.value.subject,
      message: form.value.message,
      date_created: Date.now(),
      timestamp: Date.now(),      
    }
    this.systemService.createSupportRequest(postData)
    .then((res: any) => {
      this.openSnackBar('Support request sent successfully! We would get back to you within 24 hours', 'warning')
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  generateSupportID(): number {
   let rand = Math.floor(Math.random()*50000)+123456789;
   return rand;
  }

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
