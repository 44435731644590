import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { ipifyBaseUrl } from './system.constants';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private headers;
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService) { 
      this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Version', '2.0.0')
      .set('Role', 'customer');
  }

  // Ethion API Service Methods

  // API get method
  async get(url, version){
    /** spinner starts on init */
    this.spinner.show();
    return new Promise((resolve, reject) => {
      if(!url) reject("URL is missing");
      if(!version) reject("version is missing");
      this.headers.set('accept-version', version);
      var token = localStorage.getItem('basic_token');
      this.http.get(url, {'headers': this.headers.set('Authorization', `${token}`)})
        .subscribe((res: any) => {
          resolve(res);
          /** spinner ends */
          return this.spinner.hide();
        }, err => {
          reject(err)
          /** spinner ends */
          return this.spinner.hide();
        });
    });
  }

  // API post method
  async post(url, version, postdata){
    /** spinner starts on init */
    this.spinner.show();
    return new Promise((resolve, reject) => {
      if(!url) reject("URL is missing");
      if(!version) reject("version is missing");
      this.headers.set('accept-version', version);
      var token = localStorage.getItem('basic_token');
      this.http.post(url, postdata, {'headers': this.headers.set('Authorization', `${token}`)}) 
        .subscribe((res: any) => {
          resolve(res);
          /** spinner ends */
          return this.spinner.hide();
        }, err => {
          reject(err);
          /** spinner ends */
          return this.spinner.hide();
        })
    })
  }

  // API put method
  async put(url, version, postdata){
     /** spinner starts on init */
     this.spinner.show();
    return new Promise((resolve, reject) => {
      if(!url) reject("URL is missing");
      if(!version) reject("version is missing");
      this.headers.set('accept-version', version);
      var token = localStorage.getItem('basic_token');
      this.http.put(url, postdata, {'headers': this.headers.set('Authorization', `${token}`)}) 
        .subscribe((res: any) => {
          resolve(res);
          /** spinner ends */
          return this.spinner.hide();
        }, err => {
          reject(err);
          /** spinner ends */
          return this.spinner.hide();
        })
    })
  }

  // API delete method
  async delete(url, version){
     /** spinner starts on init */
     this.spinner.show();
    return new Promise((resolve, reject) => {
      if(!url) reject("URL is missing");
      if(!version) reject("version is missing");
      this.headers.set('accept-version', version);
      var token = localStorage.getItem('basic_token');
      this.http.delete(url, {'headers': this.headers.set('Authorization', `${token}`)}) 
        .subscribe((res: any) => {
          resolve(res);
          /** spinner ends */
          return this.spinner.hide();
        }, err => {
          reject(err);
          /** spinner ends */
          return this.spinner.hide();
        })
    })
  }

  lookUpIpAddress() {
    /** spinner starts on init */
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.http.get(ipifyBaseUrl)
        .subscribe((res: any) => {
          resolve({ 
            ip_address: res.ip 
          });
          /** spinner ends */
          return this.spinner.hide();
        }, err => {
          reject(err);
          /** spinner ends */
          return this.spinner.hide();
        })
    })
  }

}
