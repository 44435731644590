import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ProductService } from '../../../../providers/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { VendorService } from '../../../../providers/vendor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.sass']
})
export class ProductDetailsComponent implements OnInit {

  public config: SwiperConfigInterface={};
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;
  public product: any = {};
  public products: any = [];
  public reviews; any = [];
  public vendor = {
    user: {
      user_id: '',
      avatar: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    },
    profile: {
      business_name: '',
      business_location: '',
      business_city: '',
      business_state: '',
      country_currency_symbol: '',
      delivery_price: '',
      delivery_message: '',
    },
  };
  public counter: number = 1;
  bigProductImageIndex = 0;
  ratings: any;
  product_id: any;

  constructor(public snackBar: MatSnackBar, private vendorService: VendorService, private route: ActivatedRoute, public productService: ProductService, public dialog: MatDialog, private router: Router, private cartService: CartService) {
    this.route.params.subscribe(params => {
      const product_id = params['id'];
      this.getProductData(product_id)
    });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },
      }
    }
  }

  // Get products information
  getProductData(id) {
    this.productService.getProductDetails(id)
    .then((product: any) => {
      this.product = product;
      this.getVendorCompleteDetails(product.vendor_id, id);
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })
  }

  // Get vendor information
  getVendorCompleteDetails(vendor_id, id) {
    this.vendorService.getVendorCompleteDetails(vendor_id)
    .then((vendor: any) => {
      this.vendor = vendor;
      this.getProductReviews(id);
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })
  }

  // get product reviews
  getProductReviews(product_id) {
    this.productService.getProductReviews(product_id)
    .then((reviews: any) => {
      this.reviews = reviews;
      this.getRelatedProducts();
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })
  }

  // Get related products
  getRelatedProducts() {
    var state = localStorage.getItem('state');
    this.productService.getProducts(state)
    .then((product: any) => {
      this.products = product
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })

  }

  // Open products dialog
  public openProductDialog(product, bigProductImageIndex) {
    let dialogRef = this.dialog.open(ProductZoomComponent, {
      data: {product, index: bigProductImageIndex },
      panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/products', product.product_id, product.name]);
      }
    });
  }

  // Toggle ratings
  rate(ratings) {
    this.ratings = Number(ratings)
  }

  // Submit rating
  submitRating(form: NgForm) {
    if (!form.value.full_name || !form.value.message) {
      return this.openSnackBar('All fields are required!', 'success')
    }
    if (!this.ratings) {
      return this.openSnackBar('Please rate this product by selecting your satisfaction level using the emoji faces!', 'success')
    }
    const postData = {
      vendor_id: this.vendor.user.user_id,
      product_id: this.product.product_id,
      reviewer_avatar: 'https://firebasestorage.googleapis.com/v0/b/flame-822f3.appspot.com/o/assets%2Fuser.svg?alt=media&token=75a25dd7-92c1-41f1-a148-85751e6c9975',
      reviewer_name: form.value.full_name,
      review_message: form.value.message,
      ratings: this.ratings,      
    }
    this.createProductReview(postData)
  }

  // Create product review
  createProductReview(postData) {
    this.productService.createReviewProduct(postData)
    .then((res: any) => {
      this.openSnackBar('Hurray! Your review has been submitted successfully.', 'success')
      location.reload();
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'success')
    })
  }

  /* Add/Remove quantity starts */
  public increment() {
    this.counter += 1;
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }
  /* Add/Remove quantity ends */

  /* Add to cart starts */
  public addToCart(product, quantity) {
    if (quantity == 0) {
       return this.openSnackBar('Select quantity!', 'success');
    }
    this.cartService.addToCart(product, parseInt(quantity));
  }

  public buyNow(product, quantity) {
    if (quantity == 0) {
      return this.openSnackBar('Select quantity!', 'success');
    }
    this.cartService.addToCart(product,parseInt(quantity));
    this.router.navigate(['/pages/checkout']);
  }
  /* Add to cart ends */

  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}


