import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { urls } from './system.constants';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

	constructor(private http: HttpService) { 

  }

  // Get products
	getProducts(state) {
    return new Promise((resolve, reject) => {
      this.http.get(urls.products + "/" + "state" + "/" + state, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })       
  }

  // Get product details with product_id
  getProductDetails(product_id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(urls.products + "/" + product_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        },err => {
         reject(err);
        })
    })      
  }

  // Get products according to category
	getProductsByCategory(category, state) {
    return new Promise((resolve, reject) => {
    this.http.get(urls.products + "/" + "category" + "/" + category + "/" + state, '1.0.0')
      .then((res: any) => {
      resolve(res);
      },err => {
       reject(err);
      })
   })		
  }

  // Get product reviews
  getProductReviews(product_id: string) {
    return new Promise((resolve, reject) => {
   this.http.get(urls.reviews + "/" + "product" + "/" + product_id, '1.0.0')
     .then((res: any) => {
     resolve(res);
     },err => {
      reject(err);
     })
    })  
  } 

  // Create a new product review
  async createReviewProduct(postData) {
    return new Promise((resolve, reject) => {
    this.http.post(urls.reviews + "/" + "product", '1.0.0', postData)
      .then((res: any) => {
      resolve(res);
      },err => {
       reject(err);
      })
    })
  }
  
}