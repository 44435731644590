import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VendorService } from '../../../../providers/vendor.service';

@Component({
  selector: 'app-vendors-list',
  templateUrl: './vendors-list.component.html',
  styleUrls: ['./vendors-list.component.sass']
})
export class VendorsListComponent implements OnInit {

  public sidenavOpen: boolean = true;
  public animation: any; 
  public sortByOrder: string = '';
  public page: any;
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public allItems: any = [];
  public vendor: any = [];

  constructor(public snackBar: MatSnackBar, private vendorService: VendorService, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.getVendorsByState();
  }

  getVendorsByState() {
    var state = 'Lagos';
    this.vendorService.getVendors(state)
    .then((res: any) => {
      this.allItems = res;
      this.vendor = res.slice(0.8);
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
    })
    .catch(err => {
      this.openSnackBar(err.error.message, 'warning')
    })
  }

  // Change vendor view
  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
  
  /* Animations starts */
  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }
  /* Animations ends */

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

  // When page is changes
  public onPageChanged(event){
    this.page = event;
    this.allItems;
    window.scrollTo(0,0);
  }
  
  // Present snackbar
  openSnackBar(message, status) {
    this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 5000 });
  }

}
