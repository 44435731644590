import { Injectable } from '@angular/core';
import { SystemService } from './system.service';
import { Functions } from '../providers/functions.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private geoCoder;

	constructor(private functions: Functions, private systemService: SystemService) { }

  // Get all countries
  getAllCountries(callback) {
    this.systemService.getCountries()
    .then((res: any) => {
      callback(null, res)
    })
    .catch(err => {
    
    })
  }

  // Retrieve current country
  getCurrentCountry(countries, callback) {
    // Define geocoder
    this.geoCoder = new google.maps.Geocoder;
    this.getIpLocation((error, address) => {
      if (error) {
        this.getIpLocation(callback)
        //callback({ error: { message: 'Snap! Unable to detect your current location'} }, null, null)
      }
      if (address) {
        var country = address.country.toLowerCase()
        var response = countries.filter((item) => {
          return item.country_name.toLowerCase() == country;
        })
        if (response.length > 0) {
          var address = address;
          var country = response[0];
          callback(null, address, country)
        }
        if (response.length == 0) {
          callback({ error: { message: 'Oops! Your country is not yet supported'} }, null, null)
        }
      }
    })
  }

  /* Start address lookup */
  // Get ip location
  getIpLocation(callback) {
    this.systemService.getIpAddress()
    .then((res: any) => {
      this.getCoordinates(res.ip_address, callback)
    })
    .catch(err => {
      // Set location using client GPS
      // this.setCurrentLocation(callback);
      window.alert(err.error.message);
    })
  }

  // Get coordinates
  getCoordinates(ip, callback) {
    this.systemService.getCoordinates(ip)
    .then((res: any) => {
     // this.getAddress(res.latitude, res.longitude, callback)
    })
    .catch(err => {
      // Set location using client GPS
      // this.setCurrentLocation(callback);
      window.alert(err.error.message);
    })
  }

  // Set location using client GPS
  setCurrentLocation(callback) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        //this.getAddress(position.coords.latitude, position.coords.longitude, callback);
      });
    }
  }

  // Get address
  getAddress(latitude, longitude, callback) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
      if (status == 'OK') {
        if (results[0]) {
            var address =  this.functions.returnLoopedAddress(results[0].address_components, (response) => {
              if (response) {
                return response;
              }
            })
            return callback(null, address)
        } else {
          return callback({ error: { message: 'Oops! We are unable to find your Location, Please make sure your device location service is turned on.' }}, null);
        }
      } else {
        return callback({ error: { message: 'Oops! We are unable to find your Location, Please make sure your device location service is turned on.' }}, null);
      }
    })
  }
  /* End address lookup */

  // Get available states
  getAvailableStates(country, address, isChanged, callback) {
    this.systemService.getAvailableStates(country.country_name)
    .then((res: any) => {
      if (isChanged == true) {
        address.administrative_area_level_1 = res[0].state || null;
      }
      this.saveToLocalStorage(country, address)
      callback(null, res, address, country)
    })
    .catch(err => {
      callback({ error: { message: 'Oops! Your country is not yet supported'} }, null, null, null)
    })
  }

  // Save to localStorage
  saveToLocalStorage(country, address) {
    localStorage.setItem('state', address.administrative_area_level_1)
    localStorage.setItem('country', country.country_name)
    localStorage.setItem('country_currency_short', country.country_currency_short)
  }

}